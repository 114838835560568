import React from "react"
import SEO from "components/seo"
import Heading from "components/Heading"
import HeroImageFull from "components/HeroImageFull"
import { FAQItem, FAQGroup } from "components/FAQGroup"

const ViewFAQ = ({ pageData }) => {
  const { heading, questions, page } = pageData

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--faq--hero">
        <HeroImageFull
          heroTextClassname="mobile--is-center"
          imageName="faqHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="black is-heading">{page}</Heading>
        </HeroImageFull>
      </div>

      <div className="max-container wrapper--faq--block-questions">
        <div className="container max-1048">
          <div className="columns">
            <div className="column is-full">
              <Heading>{heading}</Heading>
              <div className="faq-list-section">
                <FAQGroup>
                  {questions.map((question, index) => (
                    <FAQItem
                      question={question.question}
                      answers={question.answer}
                      pos={index}
                      key={index}
                      initState={Boolean(index === 0)}
                    />
                  ))}
                </FAQGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewFAQ
