import React, { useState } from "react"
import cx from "classnames"
import { motion, AnimatePresence } from "framer-motion"
import Interweave from 'interweave';
import Img from "components/Img"

const variants = {
  open: { height: "auto", scale: 1, opacity: 1 },
  closed: { height: 0, scale: 0.9, opacity: 0 },
}

function FAQGroup({ children }) {
  return <ul className="list-faq-group">{children}</ul>
}

function FAQItem({ initState, pos, question, answers }) {
  const [isOpen, setOpen] = useState(initState || false)
  const onClickHandler = e => {
    setOpen(!isOpen)
  }

  return (
    <li>
      <div className="faq-group--content">
        <h5
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          tabIndex="0"
          className={cx({
            "faq-group--question": true,
            "is-opening": isOpen,
          })}
        >
          {question}
          <div className="faq-group--icon">
            <Img
              className="icon"
              name={isOpen ? "iconFAQClose":"iconFAQOpen"}
              alt={question}
              svg={true}
            />
          </div>
        </h5>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={variants}
            >
              {
                answers && (
                  <div className="faq-group--answers">
                    <Interweave
                      content={answers}
                      // matchers={[new UrlMatcher('url'), new HashtagMatcher('hashtag')]}
                    />
                  </div>
                )
              }
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </li>
  )
}

FAQGroup.defaultProps = {
  className: "",
}

export { FAQItem, FAQGroup }
