const motionVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
  hiddenFromLeft: {
    opacity: 0,
    x: -35,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
  hiddenFromRight: {
    opacity: 0,
    x: 35,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
  fadeFromLeft: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
  fadeFromRight: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
}

const fadeVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  }
}


const heroTextVariants = {
  hidden: {
    opacity: 0,
    y: 25,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: .325,
      ease: "easeInOut",
      delay: .225,
    },
  },
}

export {
  fadeVariants,
  motionVariants,
  heroTextVariants
}
