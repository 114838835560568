import React, { useState, useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import { motionVariants, heroTextVariants } from "utils/motion.utils"
import Img from "components/Img"

const HeroImageFull = ({ heroTextClassname, className, imageProps, imageName, children }) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const imageController = useAnimation();
  const textController = useAnimation();
  const sequence = async () => {
    imageController.start("fadeFromLeft");
    textController.start('visible')
  }

  useEffect(() => {
    if (imageLoaded) {
      sequence()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLoaded])

  return (
    <motion.div className="container hero-container">
      <div className="hero-column is-relative hero-column--full has-one-row">
        <motion.div
          variants={motionVariants}
          initial="hiddenFromLeft"
          animate={imageController}
          className="element-motion"
        >
          <Img
            imageProps={{
              ...imageProps,
              onLoad: () => setImageLoaded(true),
            }}
            className="hero-image-wrapper"
            name={imageName}
            isFluid={true}
          />
        </motion.div>
        <div className={`hero-text ${heroTextClassname}`}>
          <motion.div
            initial={"hidden"}
            animate={textController}
            variants={heroTextVariants}
            className="hero-text-animation"
          >
            {children}
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}

HeroImageFull.defaultProps = {
  className: "",
  heroTextClassname: "",
  imageProps: {}
}

export default HeroImageFull
